import { animate, stagger } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from "../../../assets/logo/header-logo.png";
import { clsx } from '../../../utils/utils';
import Button from '../Button/Button';
import CompanyDropdown from './CompanyDropdown/CompanyDropdown';
import "./Header.scss";
import ProductsDropdown from './ProductsDropdown/ProductsDropdown';
import ResourcesDropdown from './ResourcesDropdown/ResourcesDropdown';
import ServiceDropdown from './ServiceDropdown/ServiceDropdown';

const Header = () => {
    const navLinks = useMemo(() => {
        return (
            [
                { name: "Products", dropdown: <ProductsDropdown />, },
                { name: "Services", dropdown: <ServiceDropdown />, },
                { name: "Resources", dropdown: <CompanyDropdown />, },
                { name: "Company", className: "resources", dropdown: <ResourcesDropdown />, },
                { name: "Contact Us", to: "/" },
            ]
        )
    }, [])

    const [scrolled, setScrolled] = useState(window.scrollY > 0);
    const [width, setWidth] = useState(document.body.clientWidth);
    const [active, setActive] = useState(false);

    const ref = useRef([]); // header elements
    const borderBottom = useRef(null);

    const handleScroll = useCallback(() => {
        setScrolled(window.scrollY > 0);
    }, [])

    const handleResize = useCallback(() => {
        setWidth(document.body.clientWidth);
    }, [])

    const handleToggle = useCallback(() => {
        setActive((prev) => !prev);
    }, [])


    useEffect(() => {
        document.querySelector("body").style.overflow = active ? "hidden" : "";
    }, [active])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("scroll", handleScroll)
            window.removeEventListener("resize", handleResize)
        }
    }, [handleScroll, handleResize])

    useEffect(() => {
        // staggering header elements
        if (ref.current) {
            animate(ref.current, {
                y: [-100, 0],
                opacity: [0, 1],
            }, {
                delay: stagger(0.05),
            })
        }
    }, [])

    useEffect(() => {
        // animating border of header
        if (borderBottom.current) {
            animate(borderBottom.current, {
                scaleX: [0, 1],
            }, {
                duration: 0.5,
                type: "spring",
            })
        }
    }, [])

    return (
        <>
            {
                width < 768 &&
                <div onClick={handleToggle} className={clsx("header_backdrop", active && "active")} />
            }
            <header className={clsx("header", scrolled && "scrolled")}>
                <span ref={borderBottom} className="border_bottom"></span>
                <Container>
                    <div className="header_in">
                        <Link ref={ele => ref.current[0] = ele} to="/" className='logo'>
                            <img src={logo} alt="logo" />
                        </Link>
                        <div className="header_content">
                            <Accordion className={clsx("header_nav", active && "active")} id="header-nav">
                                <Link to="/" className='d-md-none d-block nav_logo'>
                                    <img src={logo} alt="logo" />
                                </Link>
                                <ul>
                                    {
                                        navLinks.map((item, index) => {
                                            return (
                                                <li key={item.name} className={clsx(item.className)}>
                                                    <Accordion.Item eventKey={item.name} className={clsx(item.dropdown && "has_dropdown")}>
                                                        <Accordion.Header>
                                                            <Link ref={ele => ref.current[index + 1] = ele} aria-label={item.name} className={item.dropdown && "has_dropdown"} to={item.to}>
                                                                {item.name}
                                                            </Link>
                                                        </Accordion.Header>
                                                        {
                                                            item.dropdown
                                                            &&
                                                            <Accordion.Body>
                                                                {item.dropdown}
                                                            </Accordion.Body>
                                                        }
                                                    </Accordion.Item>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </Accordion>
                            <Button ref={ele => ref.current[navLinks.length + 1] = ele} title="login" data-no-transition className="action_btn">Coming Soon</Button>
                            {width < 768 && <button ref={ele => ref.current[navLinks.length + 2] = ele} title="toggle" onClick={handleToggle} className={clsx("toggle_btn", active && "active")}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>}
                        </div>
                    </div>
                </Container>
            </header>
        </>
    )
}

export default Header
