import React, { useRef } from 'react'
import "./Enterprise.scss";
import { Container } from 'react-bootstrap';
import Heading from '../../../common/Heading/Heading';
import useAnimateCards from '../../../../utils/useAnimateCards';

const Enterprise = () => {
    const cards = [
        {
            title: "Issue Tokenized Real Estate Tokens",
            description: "Set up asset information, compliance, supply, and transfer rules while automating token deployment with no-code solutions or APIs. ",
        },
        {
            title: "Efficient Capital Raising",
            description: "Enable multiple distribution channels and connect to DeFi, all while enforcing compliance in every transaction.",
        },
        {
            title: "Distribute to Open Networks",
            description: "Enable multiple distribution channels and connect to DeFi, all while enforcing compliance in every transaction.",
        },
    ]
    const ref = useRef([]);
    useAnimateCards(ref);
    return (
        <section className="enterprise">
            <Container>
                <div className="enterprise_box">
                    <Heading
                        heading="Enterprise-Grade Real Estate Tokenization"
                    />
                    <div className="enterprise_cards">
                        {
                            cards.map((item, index) => {
                                return (
                                    <div ref={ele => ref.current[index] = ele} className="enterprise_card" key={item.title}>
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Enterprise
