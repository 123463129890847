import { animate } from 'framer-motion';
import React, { useCallback, useRef, useState } from 'react';
import { StarIcon } from '../../../../../../assets/icons/icons';
import { clsx } from '../../../../../../utils/utils';

const FavBtn = () => {
    const [active, setActive] = useState(false);
    const ref = useRef([]);
    const handleClick = useCallback(() => {
        setActive(!active);
        animate(
            ref.current[0],
            {
                y: [0, -10, -15],
                x: [0, 10, 15],
                scale: [0, 1, 0],
            },
            {
                duration: 0.6,
            }
        )
        animate(
            ref.current[1],
            {
                y: [0, -10, -15],
                x: [0, -10, -15],
                scale: [0, 1, 0],
            },
            {
                duration: 0.4,
                delay: 0.1,
            }
        )
        animate(
            ref.current[2],
            {
                y: [0, -10, -15],
                // x: [0, -10, -15],
                scale: [0, 1, 0],
            },
            {
                duration: 0.6,
                delay: 0.25,
            }
        )
    }, [active])
    return (
        <button
            onClick={handleClick}
            className={clsx("favourite", active && "active")}
        >
            <StarIcon />
            <span ref={ele => ref.current[0] = ele} className='star star_one'><StarIcon /></span>
            <span ref={ele => ref.current[1] = ele} className='star star_two'><StarIcon /></span>
            <span ref={ele => ref.current[2] = ele} className='star star_three'><StarIcon /></span>
        </button>
    )
}

export default FavBtn
