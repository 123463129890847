import React from 'react'
import "./ServiceDropdown.scss";
import legal from "../../../../assets/icons/realty-legal.png";
import DropdownItem from '../DropdownItem/DropdownItem';

const ServiceDropdown = () => {
    const services = [
        {
            title: "Realty Legal",
            icon: legal,
            description: "Legal Support for Real Estate Tokenization",
            to: "https://realtokenize.io/realtylegal/",
        },
        {
            title: "Realty Tokenization Consulting",
            icon: legal,
            description: "Real Estate Tokenization Consulting",
            to: "https://realtokenize.io/realty-tokenization-consulting/",
        },
        {
            title: "Realty Check",
            icon: legal,
            description: "Financial and Legal Validate Network",
            to: "https://realtokenize.io/realitycheck/",
        },
    ];
    return (
        <div className='service_dropdown custom_dropdown'>
            <div className="dropdown_in">
                {
                    services.map((item) => {
                        return (
                            <DropdownItem
                                key={item.title}
                                {...item}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ServiceDropdown
