import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import marketplace from "../../../../assets/icons/marketplace.png";
import property from "../../../../assets/icons/property.png";
import trade from "../../../../assets/icons/trade.png";
import useAnimateCards from '../../../../utils/useAnimateCards';
import "./Hero.scss";

const Hero = () => {
    const cards = [
        { icon: property, title: "TOKENIZE Property" },
        { icon: marketplace, title: "BROWSE Market Place" },
        { icon: trade, title: "INVEST and TRADE" },
    ]
    
    const ref = useRef([]);
    useAnimateCards(ref);

    return (
        <section className="hero_sec">
            <Container>
                <div className="hero_in">
                    <h1 ref={ele => ref.current[0] = ele}>Transform Real Estate into Digital Assets and Invest Smarter while Trading Faster</h1>
                    <p ref={ele => ref.current[1] = ele }>Launching soon. Stay tuned!</p>
                    <div className="hero_cards">
                        {
                            cards.map((item, index) => {
                                return (
                                    <div ref={ele => ref.current[2 + index] = ele} className="card_col" key={index}>
                                        <div className="hero_card">
                                            <img src={item.icon} alt={item.title} />
                                            <h3>{item.title}</h3>
                                        </div>
                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Hero
