import React from 'react';
import blocks from "../../../../assets/icons/real-tokenize.png";
import build from "../../../../assets/icons/realty-build.png";
import suite from "../../../../assets/icons/realty-suite.png";
import tokenize from "../../../../assets/icons/realty-tokenize.png";
import transfer from "../../../../assets/icons/realty-transfer.png";
import validator from "../../../../assets/icons/realty-validator.png";
import rwa from "../../../../assets/icons/rwa-university.png";
import DropdownItem from '../DropdownItem/DropdownItem';
import "./ProductDropdown.scss";

const ProductsDropdown = () => {
    const products = [
        {
            title: "Real Tokenize",
            // subtitle: "Marketplace",
            description: "The Real Tokenize Marketplace offers seamless property Tokenization and Investments",
            icon: blocks,
            to: "https://realtokenize.io/",
        },
        {
            title: "Realty Tokenize",
            description: "Realty Tokenize revolutionizes Property Tokenization through blockchain technology",
            icon: tokenize,
            to: "https://realtytokenize.io/",
        },
        {
            title: "Realty Transfer",
            subtitle: "Transfer Agent",
            description: "Realty Transfer Agent offers Cap Table Management and Property Ownership Transfers",
            icon: transfer,
            to: "https://realtytransfer.io/",
        },
        {
            title: "Realty Suite",
            description: "Realty Suite Offers White-Label RE Tokenization Platform for Enterprises",
            icon: suite,
            to: "https://realtysuite.io/",
        },
        {
            title: "Realty Build",
            description: "Build your Real Estate Tokenization Business with Real Tokenize Experts",
            icon: build,
            to: "https://realtybuild.io/",
        },
        {
            title: "Realty Validator",
            icon: validator,
            // description: "(Automated Token Validator , Financial and Legal Validators for utility and STO’s and other token types )",
            list: [
                { to: "https://realtokenize.io/realtylegal/", name: "Realty Legal", },
                { to: "https://realtokenize.io/realitycheck/", name: "Realty Check", },
                { to: "https://realtokenize.io/realty-tokenization-consulting/", name: "Realty Tokenization Consulting", },
            ],
            // to: "",
        },
        {
            title: "RWA University",
            description: "A place of education learning, growth for RE Tokenization enthusiasts",
            icon: rwa,
            to: "https://rwa.university/",
        },
    ]
    return (
        <div className='products_dropdown custom_dropdown'>
            <div className="dropdown_in">
                {
                    products.map((item) => {
                        return (
                            <DropdownItem
                                key={item.title}
                                {...item}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ProductsDropdown
