import React, { useRef } from 'react'
import "./Leading.scss";
import { Col, Container, Row } from 'react-bootstrap';
import Heading from '../../../common/Heading/Heading';
import { TickIcon } from '../../../../assets/icons/icons';
import { S3 } from '../../../../utils/constants';
import useAnimateCards from '../../../../utils/useAnimateCards';
const leading = S3.LEADING;

const Leading = () => {
    const ref = useRef([]);
    useAnimateCards(ref);
    const list = [
        "Enforce on-chain compliance",
        "Identify investors via digital identity",
        "Open-source suites of smart contracts",
    ]
    return (
        <section className="leading_sec">
            <Container>
                <Row className='align-items-center'>
                    <Col md={6}>
                        <div className="leading_left">
                            <Heading
                                heading="Leading the Way in Secure and Compliant Tokenization"
                                description="ERC 3643 sets the standard for secure and compliant real estate tokenization, providing a streamlined solution for tokenizing assets. With built-in regulatory compliance, identity-based security, and platform interoperability, it ensures that transactions are both safe and legally sound. The flexible governance options allow issuers to maintain control over asset transfers and compliance protocols. By adopting ERC 3643, real estate tokenization becomes more accessible, secure, and scalable for investors and issuers alike"
                            />
                            <ul>
                                {list.map((item, index) => <li key={index + item} ref={ele => ref.current[index] = ele} ><TickIcon /> {item}</li>)}
                            </ul>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="leading_img">
                            <img ref={ele => ref.current[list.length] = ele} src={leading} alt="Leading the Way in Secure and Compliant Tokenization" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Leading
