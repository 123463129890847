import React from 'react'
import "./CompanyDropdown.scss";
import blogs from "../../../../assets/icons/blogs.png";
import spot from "../../../../assets/icons/spot.png";
import technological from "../../../../assets/icons/technological-insights.png"
import announcements from "../../../../assets/icons/announcements.png";
import DropdownItem from '../DropdownItem/DropdownItem';

const CompanyDropdown = () => {
    const resources = [
        {
            title: "Blogs and Articles",
            icon: blogs,
            to: "https://realtokenize.io/blog/",
        },
        {
            title: "Spot Lights and Achievements",
            icon: spot,
            to: "https://realtokenize.io/blog/",
        },
        {
            title: "Technological Insights",
            icon: technological,
            to: "https://realtokenize.io/blog/",
        },
        {
            title: "Announcements",
            icon: announcements,
            to: "https://realtokenize.io/blog/",
        },
    ];
    return (
        <div className='company_dropdown custom_dropdown'>
            <div className="dropdown_in">
                {
                    resources.map((item) => {
                        return (
                            <DropdownItem
                                key={item.title}
                                {...item}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default CompanyDropdown
