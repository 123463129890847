import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import { S3 } from '../../../../utils/constants';
import Heading from '../../../common/Heading/Heading';
import "./Grow.scss";
import useAnimateCards from '../../../../utils/useAnimateCards';
import { clsx } from '../../../../utils/utils';
const monthly = S3.MONTHLY;
const noDownPayment = S3.DOWNPAYMENT;
const earn = S3.EARN;
const control = S3.CONTROL;
const diverse = S3.DIVERSE;
const appreciation = S3.APPERCIATION;

const Grow = () => {
    const ref = useRef([]);
    useAnimateCards(ref);

    const cards = [
        {
            icon: monthly,
            name: "Monthly income",
            description: "Rent collected is distributed every month",
            cardName: "monthly",
            sm: true,
        },
        {
            icon: noDownPayment,
            name: "No Down payment",
            description: "Acquire fractional ownership , rental returns and property appreciation",
            cardName: "down_payment",
            sm: true,
        },
        {
            icon: earn,
            name: "Earn rent today, appreciation tomorrow",
            description: "Get rent payouts (monthly or quarterly) and collect property appreciation when you cash out",
            cardName: "earn",
            cardType: "earn_lg",
            lg: true,
        },
        {
            icon: control,
            name: "Keep full control Of your investments",
            description: "Forget expensive brokers and lock-in periods.Easily reinvest your rental income for the long term, or list your holdings for sale whenever you like",
            cardName: "control",
            lg: true,
        },
        {
            icon: appreciation,
            name: "Appreciation",
            description: "Track your investment progress in real time through your portfolio as assets are valued in real time",
            cardName: "appreciation",
            cardType: "appreciation_sm",
            sm: true,
        },
        {
            icon: diverse,
            name: "Diverse & High Quality Properties",
            description: "Invest in High Quality and Diverse range of properties",
            cardName: "diverse",
            cardType: "diverse_sm",
            sm: true,
        },
    ]
    return (
        <section className="grow_sec">
            <Container>
                <Heading
                    heading={"Grow Your Wealth with a Digital Real Estate Portfolio Globally"}
                    description={"Seize global investment opportunities and expand your assets through tokenized real estate, effortlessly and securely"}
                />
                <div className="grow_cards">
                    {
                        cards.map((item, index) => {
                            return (
                                <div key={item.name} className={clsx(item.lg && "grow_card_lg", item.sm && "grow_card_sm", item.cardType)} ref={ele => ref.current[index] = ele}>
                                    <div className={clsx("grow_card", item.cardName)}>
                                        <img src={item.icon} alt={item.name} />
                                        <h3>{item.name}</h3>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Container>
        </section>
    )
}

export default Grow
