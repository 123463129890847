export const TickIcon = () => <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.97795 10.6746C4.85387 10.7994 4.68454 10.869 4.50868 10.869C4.33283 10.869 4.1635 10.7994 4.03941 10.6746L0.291699 6.92622C-0.097233 6.53729 -0.097233 5.9066 0.291699 5.51841L0.760969 5.04902C1.15002 4.66009 1.77997 4.66009 2.1689 5.04902L4.50872 7.38893L10.8311 1.06636C11.2202 0.677425 11.8507 0.677425 12.239 1.06636L12.7083 1.53575C13.0972 1.92468 13.0972 2.55525 12.7083 2.94356L4.97795 10.6746Z" fill="#00D779" />
</svg>

export const FB = () =>
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5519_18736)">
            <path d="M31.985 16.6054C31.985 7.75079 24.8204 0.564453 15.9925 0.564453C7.16464 0.564453 0 7.75079 0 16.6054C0 24.3692 5.50142 30.8337 12.794 32.3255V21.4177H9.5955V16.6054H12.794V12.5952C12.794 9.49925 15.3048 6.98083 18.3914 6.98083H22.3895V11.7931H19.191C18.3114 11.7931 17.5918 12.5149 17.5918 13.3972V16.6054H22.3895V21.4177H17.5918V32.5661C25.668 31.7641 31.985 24.9306 31.985 16.6054Z" fill="#00D779" />
        </g>
        <defs>
            <clipPath id="clip0_5519_18736">
                <rect width="31.985" height="32.0017" fill="white" transform="translate(0 0.564941)" />
            </clipPath>
        </defs>
    </svg>

export const X = () =>
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5519_18738)">
            <path d="M16.5941 0.564453C7.76195 0.564453 0.601562 7.72858 0.601562 16.5653C0.601562 25.402 7.76195 32.5661 16.5941 32.5661C25.4262 32.5661 32.5866 25.402 32.5866 16.5653C32.5866 7.72858 25.4262 0.564453 16.5941 0.564453Z" fill="#00D779" />
            <path d="M18.3432 15.1171L25.1893 7.15479H23.567L17.6225 14.0683L12.8746 7.15479H7.39844L14.5782 17.6093L7.39844 25.959H9.02086L15.2985 18.658L20.3126 25.959H25.7887L18.3427 15.1171H18.3432ZM9.60543 8.37677H12.0974L23.5678 24.7926H21.0758L9.60543 8.37677Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_5519_18738">
                <rect width="31.985" height="32.0017" fill="white" transform="translate(0.601562 0.564941)" />
            </clipPath>
        </defs>
    </svg>

export const TG = () =>
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5519_18742)">
            <path d="M16.1956 32.9724C25.0302 32.9724 32.1881 25.8107 32.1881 16.9715C32.1881 8.13241 25.0302 0.970703 16.1956 0.970703C7.3611 0.970703 0.203125 8.13241 0.203125 16.9715C0.203125 25.8107 7.3611 32.9724 16.1956 32.9724ZM7.52103 16.6249L22.9405 10.6765C23.6561 10.4179 24.2812 10.8512 24.0493 11.9339L24.0506 11.9326L21.4252 24.3079C21.2306 25.1853 20.7095 25.3986 19.9805 24.9853L15.9824 22.0371L14.054 23.8959C13.8407 24.1092 13.6608 24.2893 13.2477 24.2893L13.5315 20.2184L20.9414 13.5207C21.2639 13.2367 20.8694 13.0767 20.4443 13.3593L11.2873 19.1276L7.33978 17.8956C6.48285 17.6236 6.46419 17.0382 7.52103 16.6249Z" fill="#00D779" />
        </g>
        <defs>
            <clipPath id="clip0_5519_18742">
                <rect width="31.985" height="32.0017" fill="white" transform="translate(0.203125 0.971191)" />
            </clipPath>
        </defs>
    </svg>


export const Linkedin = () =>
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5519_18744)">
            <path d="M16.0091 1.61084C7.52983 1.61084 0.65625 8.488 0.65625 16.9716C0.65625 25.4553 7.52983 32.3324 16.0091 32.3324C24.4883 32.3324 31.3619 25.4553 31.3619 16.9716C31.3619 8.488 24.4883 1.61084 16.0091 1.61084ZM12.2508 23.3384H9.14187V13.3282H12.2508V23.3384ZM10.6772 12.0994C9.69521 12.0994 9.06031 11.4033 9.06031 10.5425C9.06031 9.66406 9.7144 8.98882 10.7171 8.98882C11.7199 8.98882 12.334 9.66406 12.3532 10.5425C12.3532 11.4033 11.7199 12.0994 10.6772 12.0994ZM23.6055 23.3384H20.4966V17.7909C20.4966 16.4996 20.0456 15.6228 18.9213 15.6228C18.0625 15.6228 17.5523 16.2164 17.3268 16.7876C17.2437 16.9908 17.2229 17.2789 17.2229 17.5653V23.3368H14.1123V16.5204C14.1123 15.2708 14.0724 14.2259 14.0308 13.3267H16.7319L16.8743 14.7171H16.9366C17.346 14.0643 18.3488 13.101 20.0264 13.101C22.0718 13.101 23.6055 14.4723 23.6055 17.4197V23.3384Z" fill="#00D779" />
        </g>
        <defs>
            <clipPath id="clip0_5519_18744">
                <rect width="31.985" height="32.0017" fill="white" transform="translate(0.015625 0.971191)" />
            </clipPath>
        </defs>
    </svg>
export const DiscordIcon = () =>
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5519_18749)">
            <ellipse cx="16.4066" cy="16.9715" rx="15.9925" ry="16.0008" fill="#00D779" />
            <path d="M23.9343 10.0343C22.5568 9.38966 21.0653 8.92176 19.5117 8.65142C19.4981 8.65098 19.4846 8.65354 19.472 8.65893C19.4595 8.66432 19.4483 8.6724 19.4392 8.68261C19.2528 9.02574 19.0353 9.47284 18.8903 9.81597C17.2425 9.56642 15.5666 9.56642 13.9188 9.81597C13.7738 9.46244 13.5563 9.02574 13.3595 8.68261C13.3491 8.66181 13.3181 8.65142 13.287 8.65142C11.7334 8.92176 10.2523 9.38966 8.86441 10.0343C8.85405 10.0343 8.8437 10.0447 8.83334 10.0551C6.01614 14.287 5.23934 18.4046 5.62256 22.4805C5.62256 22.5013 5.63292 22.5221 5.65364 22.5325C7.51796 23.905 9.30978 24.7368 11.0809 25.2879C11.112 25.2983 11.143 25.2879 11.1534 25.2671C11.5677 24.6952 11.9405 24.0922 12.2616 23.4579C12.2823 23.4163 12.2616 23.3747 12.2202 23.3643C11.6298 23.1356 11.0705 22.8652 10.5216 22.5533C10.4802 22.5325 10.4802 22.4701 10.5112 22.4389C10.6252 22.3557 10.7391 22.2622 10.853 22.179C10.8737 22.1582 10.9048 22.1582 10.9255 22.1686C14.4884 23.801 18.331 23.801 21.8525 22.1686C21.8732 22.1582 21.9043 22.1582 21.925 22.179C22.0389 22.2726 22.1529 22.3557 22.2668 22.4493C22.3082 22.4805 22.3082 22.5429 22.2564 22.5637C21.7179 22.886 21.1482 23.146 20.5578 23.3747C20.5164 23.3851 20.5061 23.4371 20.5164 23.4683C20.8478 24.1026 21.2207 24.7056 21.6246 25.2775C21.6557 25.2879 21.6868 25.2983 21.7179 25.2879C23.4993 24.7368 25.2911 23.905 27.1555 22.5325C27.1762 22.5221 27.1865 22.5013 27.1865 22.4805C27.6423 17.7703 26.4304 13.684 23.9758 10.0551C23.9654 10.0447 23.955 10.0343 23.9343 10.0343ZM12.8002 19.9954C11.7334 19.9954 10.8427 19.0076 10.8427 17.7911C10.8427 16.5746 11.7127 15.5868 12.8002 15.5868C13.8981 15.5868 14.7681 16.585 14.7577 17.7911C14.7577 19.0076 13.8877 19.9954 12.8002 19.9954ZM20.0193 19.9954C18.9525 19.9954 18.0617 19.0076 18.0617 17.7911C18.0617 16.5746 18.9317 15.5868 20.0193 15.5868C21.1171 15.5868 21.9872 16.585 21.9768 17.7911C21.9768 19.0076 21.1171 19.9954 20.0193 19.9954Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_5519_18749">
                <rect width="31.985" height="32.0017" fill="white" transform="translate(0.414062 0.971191)" />
            </clipPath>
        </defs>
    </svg>


export const YT = () =>
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5519_18746)">
            <ellipse cx="16.8011" cy="16.9715" rx="15.9925" ry="16.0008" fill="#00D779" />
            <path d="M27.2207 12.1303C27.0974 11.7052 26.855 11.3172 26.5176 11.0051C26.1801 10.693 25.7595 10.4677 25.2976 10.3517C23.602 9.93115 16.8007 9.93115 16.8007 9.93115C16.8007 9.93115 9.99934 9.93115 8.30285 10.3517C7.84116 10.4678 7.4207 10.6931 7.08342 11.0052C6.74614 11.3173 6.50384 11.7053 6.38071 12.1303C5.92578 13.6991 5.92578 16.9715 5.92578 16.9715C5.92578 16.9715 5.92578 20.244 6.38071 21.8128C6.50392 22.2379 6.74635 22.6259 7.0838 22.938C7.42125 23.2501 7.8419 23.4754 8.30376 23.5914C9.99934 24.0119 16.8007 24.0119 16.8007 24.0119C16.8007 24.0119 23.602 24.0119 25.2985 23.5914C25.7604 23.4754 26.181 23.2501 26.5185 22.938C26.8559 22.6259 27.0984 22.2379 27.2216 21.8128C27.6756 20.244 27.6756 16.9715 27.6756 16.9715C27.6756 16.9715 27.6756 13.6991 27.2207 12.1303ZM14.5759 19.9425V14.0005L20.2607 16.9715L14.5759 19.9425Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_5519_18746">
                <rect width="31.985" height="32.0017" fill="white" transform="translate(0.808594 0.971191)" />
            </clipPath>
        </defs>
    </svg>


export const TwitterIcon = () =>
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5519_18738)">
            <path d="M16.5941 0.564453C7.76195 0.564453 0.601562 7.72858 0.601562 16.5653C0.601562 25.402 7.76195 32.5661 16.5941 32.5661C25.4262 32.5661 32.5866 25.402 32.5866 16.5653C32.5866 7.72858 25.4262 0.564453 16.5941 0.564453Z" fill="#00D779" />
            <path d="M18.3432 15.1171L25.1893 7.15479H23.567L17.6225 14.0683L12.8746 7.15479H7.39844L14.5782 17.6093L7.39844 25.959H9.02086L15.2985 18.658L20.3126 25.959H25.7887L18.3427 15.1171H18.3432ZM9.60543 8.37677H12.0974L23.5678 24.7926H21.0758L9.60543 8.37677Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_5519_18738">
                <rect width="31.985" height="32.0017" fill="white" transform="translate(0.601562 0.564941)" />
            </clipPath>
        </defs>
    </svg>

export const StarIcon = () => <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.08019 3.89712C8.15684 1.96571 8.69517 1 9.5 1C10.3048 1 10.8432 1.96571 11.9198 3.89712L12.1984 4.3968C12.5043 4.94564 12.6573 5.22007 12.8958 5.40114C13.1343 5.5822 13.4314 5.64942 14.0255 5.78384L14.5664 5.90622C16.6571 6.37927 17.7025 6.6158 17.9512 7.41557C18.1999 8.21535 17.4872 9.04871 16.0619 10.7154L15.6932 11.1466C15.2881 11.6203 15.0856 11.8571 14.9945 12.1501C14.9034 12.443 14.934 12.759 14.9953 13.3909L15.051 13.9662C15.2665 16.19 15.3742 17.3019 14.7231 17.7962C14.072 18.2905 13.0932 17.8398 11.1357 16.9385L10.6292 16.7053C10.073 16.4492 9.79483 16.3211 9.5 16.3211C9.20517 16.3211 8.92704 16.4492 8.37076 16.7053L7.86432 16.9385C5.90677 17.8398 4.928 18.2905 4.27688 17.7962C3.62575 17.3019 3.7335 16.19 3.94899 13.9662L4.00474 13.3909C4.06597 12.759 4.09659 12.443 4.00548 12.1501C3.91438 11.8571 3.71186 11.6203 3.30683 11.1466L2.93808 10.7154C1.51276 9.04871 0.800101 8.21535 1.04881 7.41557C1.29751 6.6158 2.34288 6.37927 4.43361 5.90622L4.9745 5.78384C5.56862 5.64942 5.86568 5.5822 6.1042 5.40114C6.34272 5.22007 6.4957 4.94565 6.80165 4.3968L7.08019 3.89712Z" stroke="white" strokeWidth="1.5" />
</svg>