import { useRef } from "react";
import { Container } from "react-bootstrap";
import { S3 } from "../../../../utils/constants";
import useAnimateCards from "../../../../utils/useAnimateCards";
import Heading from "../../../common/Heading/Heading";
import "./InvestFraction.scss";
import PropertyCard from "./PropertyCard/PropertyCard";
import Slider from "react-slick";
const propertyImg = S3.PROPERTY1;
const propertyImg2 = S3.PROPERTY2;
const propertyImg3 = S3.PROPERTY3;
const propertyImg4 = S3.PROPERTY4;

const InvestFraction = () => {
  const cardData = [
    {
      img: propertyImg3,
      propertyName: "Serenity Suites",
      desc: "There are many variations of passages of Lorem Ipsum available, Alteration in injected humour There are many variations of passages of Lorem Ipsum available, Alteration in injected humour......",
      subType: "Hotel",
      progress: "50",
      amount: "2.1 Million",
      annual: "8.73",
      net: "6.50",
      annualized: "11.00",
    },
    {
      img: propertyImg2,
      propertyName: "Urban Square Mall",
      desc: "There are many variations of passages of Lorem Ipsum available, Alteration in injected humour.....",
      subType: "Shopping Mall",
      progress: "30",
      amount: "30 Million",
      annual: "6.63",
      net: "7.70",
      annualized: "13.00",
    },
    {
      img: propertyImg,
      propertyName: "Zest Hive",
      desc: "There are many variations of passages of Lorem Ipsum available, Alteration in injected humour.....",
      subType: "Coliving",
      progress: "25",
      amount: "60 Million",
      annual: "4.60",
      net: "8.50",
      annualized: "7.00",
    },
    {
      img: propertyImg4,
      propertyName: "The Greenfield Estate",
      type: "Residential",
      desc: "There are many variations of passages of Lorem Ipsum available, Alteration in injected humour.....",
      subType: "Property",
      progress: "20",
      amount: "60 Million",
      annual: "2.73",
      net: "9.50",
      annualized: "9.00",
    },
  ];

  const ref = useRef([]);
  useAnimateCards(ref);

  const settings = {
    arrows: false,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 799,
        settings: {
          slidesToShow: 1,
          arrows: true,
        },
      },
    ],
  };

  return (
    <>
      <section className="invest_fraction">
        <Container>
          <div className="invest_fraction_in">
            <Heading
              heading="Invest in Fractions of Rental Properties Across Globe"
              description="All without leaving your living room. No experience, connections, or down payments required."
            />
            <div className="property_cards">
              <Slider {...settings}>
                {cardData.map((item, index) => {
                  return (
                    <PropertyCard
                      type={item.type}
                      subType={item.subType}
                      ref={(ele) => (ref.current[index] = ele)}
                      key={index}
                      img={item.img}
                      propertyName={item.propertyName}
                      desc={item.desc}
                      progress={item.progress}
                      amount={item.amount}
                      net={item.net}
                      annual={item.annual}
                      annualized={item.annualized}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default InvestFraction;
