import React, { forwardRef } from 'react';
import "./Button.scss";
import { clsx } from '../../../utils/utils';
import Spinner from '../Spinner/Spinner';

const Button = forwardRef(({ loading, children, text, className, fluid, ...rest }, ref) => {
    return (
        <button
            type="button"
            ref={ref}
            {...rest}
            className={clsx("custom_btn", className, fluid && "w-100")}
        >
            {
                loading
                    ?
                    <Spinner />
                    :
                    text || children
            }
        </button>
    );
});

export default Button;
