import React from 'react'
import "./OverLoader.scss";
import Spinner from '../Spinner/Spinner';

const OverLoader = () => {
    return (
        <div className="over_loader">
            <Spinner />
        </div>
    )
}

export default OverLoader
