import React from "react";
import "./Footer.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo/logo.png";
import {
    DiscordIcon,
    FB,
    Linkedin,
    TG,
    TwitterIcon,
    YT,
} from "../../../assets/icons/icons";

const Footer = () => {
    const socialmedia = [
        { to: "https://www.facebook.com/", name: "Facebook", icon: FB },
        { to: "https://x.com/", name: "Twitter", icon: TwitterIcon },
        { to: "https://web.telegram.org/", name: "Telegram", icon: TG },
        { to: "https://www.youtube.com/", name: "Youtube", icon: YT },
        { to: "https://discord.com/", name: "Discord", icon: DiscordIcon },
        { to: "https://in.linkedin.com/", name: "Linked In", icon: Linkedin },
    ];

    const navList = [
        {
            title: "Company",
            links: [
                { link: "https://realtokenize.io/about-us/", name: "About Us" },
                { link: "https://realtokenize.io/team/", name: "Team" },
                { link: "https://realtokenize.io/#", name: "Press Media" },
                { link: "https://realtokenize.io/careers/", name: "Careers" },
            ],
        },
        {
            title: "Product",
            links: [
                { link: "https://realtytokenize.io/", name: "Realty Tokenize" },
                { link: "https://realtytransfer.io/", name: "Realty Transfer" },
                { link: "https://realtokenize.io/", name: "Real Tokenize" },
                { link: "https://realtysuite.io/", name: "Realty Suite" },
                { link: "https://realtybuild.io/", name: "Realty Build " },
                { link: "https://rwa.university/", name: "RWA University" },
                { link: "https://realtokenize.io/#", name: "Realty Validator" },
                { link: "https://realtokenize.io/realtylegal/", name: "Realty Legal" },
                { link: "https://realtokenize.io/realitycheck/", name: "Realty Check" },
                { link: "https://realtokenize.io/realty-tokenization-consulting/", name: "Realty Tokenization Consulting" },
            ],
        },
        {
            title: "Services",
            links: [
                { link: "https://realtokenize.io/realtylegal/", name: "Realty Legal" },
                {
                    link: "https://realtokenize.io/realty-tokenization-consulting/",
                    name: "Realty Advise",
                },
                { link: "https://realtokenize.io/realitycheck/", name: "Realty Check" },
            ],
        },
    ];

    return (
        <footer className="footer">
            <Container>
                <div className="footer_in">
                    <div className="footer_left">
                        <Link to="" className="logo">
                            <img src={logo} alt="logo" />
                        </Link>
                        <p>
                            Empower your investments with seamless trading on our innovative
                            platform. Join the digital revolution and unlock limitless
                            opportunities in real estate.
                        </p>
                        <h4 className="address">
                            Real Tokniz Platforms LLC <br />
                            Company Number : 3480LLC2024 <br />
                            P.O. Box 1510, Beachmont, Kingstown ,Saint Vincent and The
                            Grenadines
                        </h4>
                        <h3>Follow Us</h3>
                        <ul>
                            {socialmedia.map((item) => {
                                const Icon = item.icon;
                                return (
                                    <li key={item.name}>
                                        <Link
                                            to={item.to}
                                            rel="noreferrer"
                                            target="_blank"
                                            aria-label={item.name}
                                        >
                                            <Icon />
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="footer_right">
                        {navList.map((item) => {
                            return (
                                <div key={item.title} className="footer_nav">
                                    <h3>{item.title}</h3>
                                    <ul>
                                        {item.links.map((item) => {
                                            return (
                                                <li key={item.name}>
                                                    <Link to={item.link} rel="noreferrer" target="_blank">
                                                        {item.name}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="footer_bottom">
                    <ul>
                        <li>
                            <Link
                                rel="noreferrer"
                                target="_blank"
                                to="https://realtokenize.io/privacy-policy/"
                            >
                                Privacy Policy
                            </Link>
                        </li>
                        <li>
                            <Link
                                rel="noreferrer"
                                target="_blank"
                                to="https://realtokenize.io/terms-and-conditions/"
                            >
                                Terms of Use
                            </Link>
                        </li>
                        <li>
                            <Link
                                rel="noreferrer"
                                target="_blank"
                                to="https://realtokenize.io/aml-kyc-policy/"
                            >
                                AML/KYC Policy
                            </Link>
                        </li>
                        <li>
                            <Link
                                rel="noreferrer"
                                target="_blank"
                                to="https://realtokenize.io/legal-disclaimer/"
                            >
                                Legal Disclaimer
                            </Link>
                        </li>
                    </ul>
                    <p>
                        Copyright © {new Date().getFullYear()}-
                        {new Date().getFullYear() + 1} realtoknize | All rights reserved.{" "}
                    </p>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
