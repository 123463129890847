import React from 'react';
import Application from './Application';
import OverLoader from './components/common/OverLoader/OverLoader';
import { S3 } from './utils/constants';
import useImagePreloader from './utils/useImagePreloader';

const App = () => {
    const { imagesPreloaded } = useImagePreloader(Object.values(S3).map(item => item));
    return (
        imagesPreloaded  ? <Application /> : <OverLoader/>
    )
}

export default App
