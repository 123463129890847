import { motion, useInView } from 'framer-motion';
import React, { useRef } from 'react';
import { clsx } from '../../../utils/utils';
import "./Heading.scss";

const Heading = ({ heading, description, className }) => {
    const ref = useRef(null);
    const isInView = useInView(ref);
    const variants = {
        initial: {
            y: 100,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
        },
    }
    return (
        <motion.div
            initial="initial"
            animate={isInView && "animate"}
            variants={variants}
            transition={{
                type: "spring",
            }}
            className={clsx("heading", className)}
            ref={ref}
        >
            <h2>{heading}</h2>
            {
                description &&
                <p>
                    {description}
                </p>
            }
        </motion.div>
    )
}

export default Heading
