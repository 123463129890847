import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import { S3 } from '../../../../utils/constants';
import Heading from '../../../common/Heading/Heading';
import "./Invest.scss";
import useAnimateCards from '../../../../utils/useAnimateCards';
const invest =  S3.INVEST;

const Invest = () => {

    const ref = useRef([]);
    useAnimateCards(ref, false ,  0.1);

    return (
        <section className="invest_sec">
            <Container>
                <Heading
                    heading="Invest in Real Estate as Easily as Trading Stocks!"
                    description="Investing in High-Yield Real Estate Has Never Been Simpler. RealtyBlocks offers a world-class platform to grow your wealth through premium property investments"
                />
                <div className="invest_img">
                    <div ref={ele => ref.current[0] = ele} className="invest_card quick">
                        <h3>Quick signup & KYC verification process.</h3>
                    </div>
                    <div ref={ele => ref.current[1] = ele} className="invest_card own">
                        <h3>Get you own blockchain wallet instantly.</h3>
                    </div>
                    <div ref={ele => ref.current[2] = ele} className="invest_card forecast">
                        <h3>Explore, forecast & choose to  invest.</h3>
                    </div>
                    <img src={invest} alt="invest" />
                </div>
            </Container>
        </section>
    )
}

export default Invest
