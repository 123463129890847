import React, { forwardRef } from "react";
import Button from "../../../../common/Button/Button";
import FavBtn from "./FavBtn/FavBtn";
import "./PropertyCard.scss";

const PropertyCard = forwardRef(
  (
    {
      subType,
      type,
      img,
      propertyName,
      desc,
      amount,
      annual,
      net,
      annualized,
      progress,
    },
    ref
  ) => {
    return (
      <div className="property_card_col" ref={ref}>
        <div className="property_card">
          <div className="property_img">
            <img src={img} alt="" />
            <FavBtn />
          </div>
          <div className="property_card_in">
            <div className="property_header">
              <div>
                <h3>{propertyName}</h3>
                <h6>{type || "Commercial"} Property</h6>
              </div>
              <span className="subtype">{subType}</span>
              <p>{desc}</p>
            </div>
            <div className="annual_values">
              <ul>
                <li>
                  <div>
                    <div>
                      <div>
                        <h4>Annual Rental Yield</h4>
                        <p>{annual}%</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <div>
                      <div>
                        <h4>Net Rental Yield</h4>
                        <p>{net}%</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <div>
                      <div>
                        <h4>Annualized Return</h4>
                        <p>{annualized}%</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <div>
                      <div>
                        <h4>Annualized Return</h4>
                        <p>{annualized}%</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="progress_bar">
              <div className="progress_bar_in">
                <div style={{ width: `${progress}%` }} />
              </div>
              <label htmlFor="">{progress}% Funded</label>
            </div>
            <div className="card_action">
              <div>
                <h5>Property Price</h5>
                <h5>${amount} USD</h5>
              </div>

              <Button fluid className="buy_btn">
                Buy
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PropertyCard;
