import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import coLiving from "../../../../assets/icons/co-living.png";
import condos from "../../../../assets/icons/condos.png";
import hotels from "../../../../assets/icons/hotels.png";
import industries from "../../../../assets/icons/industrial.png";
import land from "../../../../assets/icons/land.png";
import multiHomes from "../../../../assets/icons/multi-family.png";
import office from "../../../../assets/icons/office.png";
import properties from "../../../../assets/icons/properties.png";
import rentals from "../../../../assets/icons/rentals.png";
import shopppingMall from "../../../../assets/icons/shopping-malls.png";
import Heading from '../../../common/Heading/Heading';
import "./Explore.scss";
import useAnimateCards from '../../../../utils/useAnimateCards';

const Explore = () => {
    const data = [
        { icon: office, title: "Office Spaces", },
        { icon: land, title: "Land and Acreages", },
        { icon: properties, title: "Special-Purpose Properties", },
        { icon: hotels, title: "Hotels and Hospitality", },
        { icon: coLiving, title: "Co-Living Spaces", },
        { icon: rentals, title: "Short Term Rentals", },
        { icon: multiHomes, title: "Multi-Family Homes", },
        { icon: condos, title: "Condos", },
        { icon: shopppingMall, title: "Shopping Malls", },
        { icon: industries, title: "Industrial Properties", },
    ]

    const ref = useRef([]);
    useAnimateCards(ref);

    return (
        <section className="explore_sec">
            <Container>
                <Heading
                    heading="Explore Diversified Assets"
                    description=" Find properties by location and asset type across India and enjoy your consistent cashflows & ROI"
                />
                <div className="explore_cards">
                    {
                        data.map((item, index) => {
                            return (
                                <div ref={ele => ref.current[index] = ele} className="explore_card_col" key={index}>
                                    <div className="explore_card">
                                        <img src={item.icon} alt={item.title} />
                                        <h3>{item.title}</h3>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Container>
        </section>
    )
}

export default Explore
