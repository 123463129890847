import React, { useRef } from 'react'
import "./Building.scss";
import { Container } from 'react-bootstrap';
import Heading from '../../../common/Heading/Heading';
import Button from '../../../common/Button/Button';
import useAnimateCards from '../../../../utils/useAnimateCards';

const Building = () => {
    const ref = useRef([]);
    useAnimateCards(ref);
    return (
        <section className="building_sec">
            <Container>
                <div className="building_in">
                    <Heading
                        heading="Start Building wealth and tokenizing asset whenever you want"
                        description="Seamlessly Build Wealth and Tokenize Your Assets On-Demand"
                    />
                    <div className="building_action">
                        <Button ref={ele => ref.current[0] = ele} data-no-transition className="light_btn">View properties</Button>
                        <Button ref={ele => ref.current[1] = ele} data-no-transition className="secondary_btn">Start tokenizing</Button>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Building
