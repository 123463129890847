import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import HomeLayout from './components/layouts/HomeLayout/HomeLayout'
import Homepage from './components/pages/Homepage/Homepage'

const Application = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <HomeLayout />,
            children: [
                {
                    index: true,
                    element: <Homepage />,
                },
            ],
        },
    ]);
    return (
        <RouterProvider router={router} />
    )
}

export default Application
