import React from 'react';
import { Link } from 'react-router-dom';
import "./DropdownItem.scss";

const DropdownItem = (props) => {
    return (
        <div key={props.title} className="dropdown_list_item">
            <Link target="_blank" rel="noreferrer" to={props.to}>
                <img src={props.icon} alt={props.title} />
                <div className='dropdown_list_item_content'>
                    <h3>{props.title} {props.subtitle && <span>({props.subtitle})</span>} </h3>
                    {props.description && <p>{props.description}</p>}
                    {props.list &&
                        <div className="dropdown_list_item_list">
                            {props.list.map((item, index) => <p onClick={() => window.open(item.to, "_blank")} key={item.name + index}>{item.name}</p>)}
                        </div>
                    }
                </div>
            </Link>
        </div>
    )
}

export default DropdownItem;