import React from 'react'
import Hero from './Hero/Hero'
import Enterprise from './Enterprise/Enterprise'
import Grow from './Grow/Grow'
import Wealth from './Wealth/Wealth'
import Explore from './Explore/Explore'
import Invest from './Invest/Invest'
import Leading from './Leading/Leading'
import Building from './Building/Building'
import InvestFraction from './InvestFraction/InvestFraction'

const Homepage = () => {
    return (
        <>
            <Hero />
            <Enterprise />
            <Grow />
            <Wealth />
            <Explore />
            <Invest />
            <Leading />
            <InvestFraction />
            <Building />
        </>
    )
}

export default Homepage
