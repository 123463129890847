import { animate, stagger, useInView } from 'framer-motion';
import { useEffect } from 'react';

const useAnimateCards = (ref, y = true, staggerDelay = 0.05,) => {
    const isInView = useInView(ref, {
        once: true,
    })
    useEffect(() => {
        if (isInView && ref.current) {
            animate(ref.current, {
                ...(y ? { y: [100, 0] } : {}), // to animate y or not
                opacity: [0, 1],
            }, {
                type: "spring",
                delay: stagger(staggerDelay),
            })
        }
    }, [isInView, ref, staggerDelay, y])
    return;
}

export default useAnimateCards
