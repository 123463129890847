import React from 'react'
import "./HomeLayout.scss";
import Header from '../../common/Header/Header';
import Footer from '../../common/Footer/Footer';
import { Outlet } from 'react-router-dom';

const HomeLayout = () => {
    return (
        <div className="home_layout">
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
        </div>
    )
}

export default HomeLayout
