export const SOCIAL_MEDIA = {
    FB: "",
    X: "",
    TG: "",
    YT: "",
    DISCORD: "",
    LINKEDIN: "",
}
export const S3_URL = "https://d2w7xb6113lp9k.cloudfront.net/";
export const S3 = {
    APPERCIATION: S3_URL + "s3___apperciation.png",
    BUILDING_BG: S3_URL + "s3___building-bg.png",
    BUILDINGSHODOWLEFT: S3_URL + "s3___buildingShodowLeft.png",
    CONTROL: S3_URL + "s3___control.png",
    DIVERSE: S3_URL + "s3___diverse.png",
    DOWNPAYMENT: S3_URL + "s3___downpayment.png",
    EARN: S3_URL + "s3___earn.png",
    ENTERPRISE_LEFT_SHADOWS: S3_URL + "s3___enterprise-left-shadows.png",
    FOOTER_SHADOW: S3_URL + "s3___footer-shadow.png",
    GROW_RIGHT_SHADOW: S3_URL + "s3___grow-right-shadow.png",
    HERO: S3_URL + "s3___hero.png",
    INVESLEFTSHADOW: S3_URL + "s3___invesLeftShadow.png",
    INVEST: S3_URL + "s3___invest.png",
    LEADING: S3_URL + "s3___leading.png",
    LENDINGBG: S3_URL + "s3___lendingBg.png",
    LENDINGSTARSBG: S3_URL + "s3___lendingStarsBg.png",
    MONTHLY: S3_URL + "s3___monthly.png",
    PROPERTY1: S3_URL + "s3___property6.png",
    PROPERTY2: S3_URL + "s3___property5.png",
    PROPERTY3: S3_URL + "s3___property3.png",
    PROPERTY4: S3_URL + "s3___property4.png",
    WEALTH_LEFT_SHADOW: S3_URL + "s3___wealth-left-shadow.png",
    WEALTH_LEFT_SHADOW_NEW: S3_URL + "s3___wealth-left-shadow-new.png",
    WEALTHRIGHT: S3_URL + "s3___wealthRight.png",
    WEALTH_RIGHT_SHADOW: S3_URL + "s3___wealth-right-shadow.png",
}