import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import bank from "../../../../assets/icons/bank.png";
import fiat from "../../../../assets/icons/fiat.png";
import management from "../../../../assets/icons/management.png";
import owners from "../../../../assets/icons/owners.png";
import pay from "../../../../assets/icons/pay.png";
import tenent from "../../../../assets/icons/tenent.png";
import Heading from "../../../common/Heading/Heading";
import "./Wealth.scss";
import useAnimateCards from "../../../../utils/useAnimateCards";

const Wealth = () => {
    const ref = useRef([]);
    useAnimateCards(ref);
    const data = [
        { icon: tenent, title: "Tenant" },
        { icon: pay, title: "Pay USD/Fiat" },
        { icon: management, title: "Management" },
        { icon: fiat, title: "Send USD Tokens/ Fiat" },
        { icon: owners, title: "RealToken Owners" },
        { icon: bank, title: "To bank Account" },
    ];
    return (
        <section className="wealth_sec">
            <div className="wealt_right_star"></div>
            <Container>
                <Heading
                    heading="Grow Your Wealth with a Digital Real Estate Portfolio Globally"
                    description="Seize global investment opportunities and expand your assets through tokenized real estate, effortlessly and securely"
                />
                <Row>
                    {data.map((item, index) => {
                        return (
                            <Col ref={ele => ref.current[index] = ele} key={item.title} md={2} sm={4} xs={6}>
                                <div className="wealth_card">
                                    <img src={item.icon} alt={item.title} />
                                    <h3>{item.title}</h3>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </section>
    );
};

export default Wealth;
