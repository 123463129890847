import React from 'react';
import about from "../../../../assets/icons/about.png";
import careers from "../../../../assets/icons/careers.png";
import press from "../../../../assets/icons/press-media.png";
import team from "../../../../assets/icons/team.png";
import DropdownItem from '../DropdownItem/DropdownItem';
import "./ResourcesDropdown.scss";

const ResourcesDropdown = () => {
    const resources = [
        {
            title: "About Us",
            icon: about,
            to: "https://realtokenize.io/about-us/",
        },
        {
            title: "Team",
            icon: team,
            to: "https://realtokenize.io/team/",
        },
        {
            title: "Press Media",
            icon: press,
            to: "#",
        },
        {
            title: "Careers",
            icon: careers,
            to: "https://realtokenize.io/careers/",
        },
    ];
    return (
        <div className='resources_dropdown custom_dropdown'>
            <div className="dropdown_in">
                {
                    resources.map((item) => {
                        return (
                            <DropdownItem
                                key={item.title}
                                {...item}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ResourcesDropdown
